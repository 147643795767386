import React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl, selectLoginUrl } from 'core/selectors/app';
import { selectUserName, selectUserIsIdentified } from 'core/selectors/user';
import { selectActiveMQ } from 'core/modules/MediaQueries/selectors';
import { useDropdown } from 'core/hooks/useDropdown';

export function UserInfoSectionWithDropdown() {
  const siteUrl = useSelector(selectSiteUrl);
  const loginUrl = useSelector(selectLoginUrl);
  const userName = useSelector(selectUserName);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const {
    hideSubnav, showSubnav, isFocused, dropdownClassName,
  } = useDropdown();

  const activeMQ = useSelector(selectActiveMQ);

  if (!userIsIdentified) {
    return (
      <div className="mn_brandLinks">
        <a className="mn_loginLink" href={loginUrl}>Log in</a>
      </div>
    );
  }

  if (['XL', 'XXL'].includes(activeMQ)) {
    return (
      <>
        <div className="mn_userInfo">
          <span className="mn_userNameWrap">Welcome, <span className="mn_userName">{userName}</span></span>
        </div>
        <div
          onMouseEnter={showSubnav}
          onMouseLeave={hideSubnav}
          onBlur={hideSubnav}
          className={`mn_navigationDropdownTrigger ${dropdownClassName}`}
        >
          <button
            className="mn_navigationItem"
            aria-expanded={isFocused}
            aria-label="Account"
            onFocus={showSubnav}
          >
            <i className="mn_usaaSVGIconMyAccount" aria-hidden="true" />
          </button>
          <div className="mn_navigationDropdown">
            <ul>
              <li><a href={`${siteUrl}/ai____.htm`}>My Account</a></li>
              <li><a href={`${siteUrl}/foryou____.htm`}>Offers For You</a></li>
              <li><a href={`${siteUrl}/logoutUser.php?lUrl=/`}>Log Out</a></li>
            </ul>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="mn_navigationDropdownTrigger">
      <div className="mn_userInfo">
        <span className="mn_userNameWrap">Welcome, <span className="mn_userName">{userName}</span></span>
      </div>
      <a href={`${siteUrl}/ai____.htm`} className="mn_navigationItem" title="Opens my account page">
        <i className="mn_usaaSVGIconMyAccount" aria-hidden="true" />
      </a>
    </div>
  );
}

export default UserInfoSectionWithDropdown;
