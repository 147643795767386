import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { addExternalAttributesIfNeeded } from 'core/utils/helpers/externalLink';
import { setHasPreviouslyLoggedIn } from 'core/utils/helpers/hasPreviouslyLoggedIn';
import { scrollToElement } from 'core/utils/helpers/scrollToElement';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';

import {
  selectSiteUrl, selectPrivacyPolicyUrl, selectTermsAndConditionsURL,
  selectToolbarConfig, selectPrivacyLinkEnabled, selectDataPrivacyPageEnabled,
  selectClientHostedDPSRedirectURL, selectBrandGdprDisplayName, selectBrandUrl,
} from 'core/selectors/app';
import { selectUserIsIdentified, selectBrowserIsMobile } from 'core/selectors/user';

import { selectDidFooterCode } from './selectors';
import './Footer.scss';

function Footer() {
  const siteUrl = useSelector(selectSiteUrl);
  const isMobile = useSelector(selectBrowserIsMobile);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const privacyPolicyUrl = useSelector(selectPrivacyPolicyUrl);
  const brandGdprDisplayName = useSelector(selectBrandGdprDisplayName);
  const enablePrivacyNoticeLink = useSelector(selectPrivacyLinkEnabled);
  const enableDonotSharePage = useSelector(selectDataPrivacyPageEnabled);
  const termsAndConditionsURL = useSelector(selectTermsAndConditionsURL);
  const clientHostedDPSRedirectURL = useSelector(selectClientHostedDPSRedirectURL);
  const { enableButtonMarketing } = useSelector(selectToolbarConfig) || {};
  const didFooterCode = useSelector(selectDidFooterCode);
  const brandUrl = useSelector(selectBrandUrl);

  useEffect(() => {
    // Set hasPreviouslyLoggedIn flag in Local Storage if user is loggedin
    userIsIdentified && setHasPreviouslyLoggedIn();
  }, []);

  const shouldShowButtonLink = !isMobile && enableButtonMarketing;
  const scrollToTop = () => scrollToElement(document.body, 0, 1000);

  const renderDataPrivacySettingsPage = () => {
    if (!userIsIdentified) {
      return (<li><a href={`${siteUrl}/dps____.htm`}>Privacy Preferences</a></li>);
    }
    if (clientHostedDPSRedirectURL) {
      return (
        <li>
          <a href={`${clientHostedDPSRedirectURL}/dps____.htm`}>Privacy Preferences</a>
        </li>
      );
    }
    return null;
  };

  return (
    <footer className="mn_brandFooter">
      <div className="mn_brandFooterInner">
        <ShowOnMQ tablet desktop>
          <ul className="mn_links">
            <li><a href={`${siteUrl}/contact____.htm`}>Contact Us</a></li>
            {shouldShowButtonLink &&
              <li><a href={`${siteUrl}/button`} className="mn_buttonFooterLink">Get the Button</a></li>
            }
            <li><a href={`${siteUrl}/faq____.htm`}>FAQs</a></li>
            <li><a href={`${siteUrl}/hiw____.htm`}>How It Works</a></li>
            <li>
              <a
                href={privacyPolicyUrl}
                className="mn_externalLink"
                {...addExternalAttributesIfNeeded(privacyPolicyUrl, siteUrl)}
              >
                Privacy Policy
              </a>
            </li>
            <li><a href={termsAndConditionsURL}>Terms</a></li>
            <li>
              <a
                href={brandUrl}
                className="mn_externalLink"
                {...addExternalAttributesIfNeeded(brandUrl, siteUrl)}
              >
                USAA.com
              </a>
            </li>
            {enablePrivacyNoticeLink &&
              <li>
                <a href={`${siteUrl}/p/privacy-notice.htm`}>{brandGdprDisplayName} Terms and Privacy Notice</a>
              </li>
            }
            {enableDonotSharePage && renderDataPrivacySettingsPage()}
          </ul>
        </ShowOnMQ>
        <div className="mn_contentWrap">
          <div className="mn_footerTermText">
            <p>
              Specific terms and conditions may apply for each merchant.
              Please check for individual policies on each merchant's information page.
            </p>
            <p>
              While we make every effort to display current prices, offers and product pricing are subject to change
              without notice and are at the discretion of the merchant.
            </p>
            <p>
              Program Terms for All Transactions: Transactions are not eligible for cash back on purchases made with
              coupons or discount codes that are not found on the USAA MemberShop site. Prices, offers and cash back
              earning rates are subject to change. Please check specific merchant restrictions and terms and
              conditions on the USAA MemberShop site to determine specific offer requirements or if purchases of gift
              cards, certificates or other cash equivalents are eligible for cash back.
            </p>
            <p>USAA means United Services Automobile Association and its affiliates.</p>
            <p>
              Use of the term "member" or "membership" refers to membership in USAA Membership Services and does not
              convey any legal or ownership rights in USAA. Restrictions apply and are subject to change.
            </p>
            <p>
              The USAA Perks program is provided through USAA Alliance Services LLC, a wholly owned subsidiary of USAA.
              USAA Alliance Services contracts with companies not affiliated with USAA to offer their products and
              services to members and customers. USAA Alliance Services receives compensation from these companies
              based on the sale of these products or services. When you purchase a product or service from one of these
              companies, that company is responsible for protecting your data and its processes and procedures may
              differ from those of USAA. These companies have sole financial responsibility for their products and
              services.
            </p>
            <p>
              The trademarks, logos and names of other companies, products and services are the property of their
              respective owners.
            </p>
            <p>
              Depending on the merchant, members receive between 0.5% and 20% of the retail purchase price in rewards
              on their purchases.
            </p>
            <p>
              Qualified Amounts are based on the net price of eligible items purchased
              (excluding tax, shipping and handling).
            </p>
            <p>* This merchant does not offer USAA MemberShop rewards.</p>
            <p>{didFooterCode}</p>
          </div>
          <button className="mn_backToTopButton mn_visible-xs mn_visible-s" onClick={scrollToTop}>Back to top</button>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
