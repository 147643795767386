import React from 'react';

import './SidebarTrigger.scss';

function SidebarTrigger({ sidebarTriggerClickHandler, isSidebarOpen }) {
  return (
    <button
      className="mn_sidebarTrigger"
      aria-expanded={isSidebarOpen}
      aria-label="Toggle navigation"
      onClick={sidebarTriggerClickHandler}
    >
      <i className="mn_sidebarTriggerOpen mn_usaaSVGIconHamburger" aria-hidden="true" />
      <i className="mn_sidebarTriggerClose mn_usaaSVGIconHamburger" aria-hidden="true" />
    </button>
  );
}

export default SidebarTrigger;
