import React from 'react';

import ChromeRating from 'core/modules/Partials/ChromeRating/ChromeRating';

function ButtonScrollingModalContent({
  redirectToChromeStore, renderedLogos, storeCount, buttonContentRef,
}) {
  return (
    <div className="mn_buttonContent" ref={buttonContentRef}>
      <div className="mn_buttonBlock mn_buttonBlockOne">
        <div className="mn_motto">The MemberShop Button</div>
        <h2>Earning Cash Back Couldn't Be Easier</h2>
        <p>
          On average, members who use the MemberShop<sup>&reg;</sup> button browser<br />
          extension earn <strong>twice as much cash back</strong> as members who do not use the button.
        </p>
        <button className="mn_button" data-number="1" onClick={redirectToChromeStore}>Add to Browser</button>
        <ChromeRating chromeText="Chrome Web Store Rating" />
      </div>
      <div className="mn_buttonBlock mn_buttonBlockTwo">
        <div className="mn_buttonOverlayMerchantLogosWrap">
          <div className="mn_buttonOverlayMerchantLogos">
            {renderedLogos}
          </div>
        </div>
        <div className="mn_motto">Never miss cash back with the button</div>
        <h2>Earn Cash Back at {storeCount}+ Stores With the Button</h2>
        <p>
          Notifications and automatic coupon alerts through the MemberShop button<br />
          help you save money and earn cash back on the shopping you're already doing.
        </p>
        <button className="mn_button" data-number="2" onClick={redirectToChromeStore}>Discover the Button</button>
      </div>

      <div className="mn_buttonBlock mn_buttonBlockThree">
        <h2>How It Works</h2>
        <div className="mn_hiwHints">
          <div>
            <h3>Install in Seconds</h3>
            <p>Download the free browser extension in just two clicks.</p>
          </div>
          <div>
            <h3>Shop as Usual</h3>
            <p>
              Shop at {storeCount}+ stores &#8212; the button will notify you when cash back is available.
            </p>
          </div>
          <div>
            <h3>Earn Cash Back</h3>
            <p>
              Click the notification to activate your earnings and<br />
              rack up<br />
              cash back.
            </p>
          </div>
        </div>
        <button className="mn_button" data-number="3" onClick={redirectToChromeStore}>Install Now</button>
      </div>

      <div className="mn_buttonBlock mn_buttonBlockFour">
        <h2>Get the Best Price & the Most Cash</h2>
        <p>
          Get notified to activate cash back while you shop and receive alerts when a lower price is available.
          Plus, the button automatically applies coupons directly to your cart.
        </p>
        <button className="mn_button" data-number="4" onClick={redirectToChromeStore}>Start Earning More</button>
        <ChromeRating chromeText="Chrome Web Store Rating" />
      </div>
    </div>
  );
}

export default ButtonScrollingModalContent;
