import React from 'react';

import './SearchBarTrigger.scss';

function SearchBarTrigger({ ariaExpanded, onClick }) {
  return (
    <div className="mn_searchTriggerWrapper">
      <button
        className="mn_searchTrigger"
        aria-label="Display search field"
        onClick={onClick}
        aria-expanded={ariaExpanded}
      >
        <i className="mn_searchTriggerOpen mn_usaaSVGIconSearch" aria-hidden="true" />
        <i className="mn_searchTriggerClose mn_usaaSVGIconClose" aria-hidden="true" />
      </button>
    </div>
  );
}

export default SearchBarTrigger;
